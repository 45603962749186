.roadmap-row {
  display: flex;
  flex-direction: row;
  border: 0px solid #333;
  padding: 0px;
  position: relative;

  .roadmap-col {
    position: relative;
    border: 0px solid #333;
    overflow: hidden;
    padding: 0;

    .roadmap-cell {
      display: flex;
    }
  }
}

@property --roadmap-color {
  syntax: "<color>";
  inherits: false;
  initial-value: #44c;
}

.roadmap-color-blue {
  --roadmap-color: var(--bs-primary);
}

.roadmap-color-red {
  --roadmap-color: var(--bs-danger);
}

.roadmap-color-orange {
  --roadmap-color: var(--bs-warning);
}

.roadmap-color-green {
  --roadmap-color: var(--bs-success);
}

.roadmap-color-grey {
  --roadmap-color: var(--bs-secondary);
}

.roadmap {
  display: flex;
  flex-direction: row;

  .roadmap-groups {
    display: flex;
    flex-direction: column;

    .roadmap-row {
      margin: 0 0 10px 0;

      .roadmap-col {
        .roadmap-cell {
          width: 100px;
          margin: 0 10px 0 0;
          height: 40px;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .roadmap-timeline {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding-bottom: 20px;
    width: 100%;

    .roadmap-timeline-group-name {
      margin-bottom: 0px;

      span {
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    .roadmap-row {
      margin: 0 0 10px 0;
      height: 40px;

      .roadmap-col {
        &.roadmap-month {
          .roadmap-cell {
            display: flex;
            height: 40px;
            box-shadow: inset 0 0 0px 2px #fff;
            border-radius: 5px;
            justify-content: center;
            align-items: center;
            background: #eee;
          }
        }

        &.roadmap-day {
          .roadmap-cell {
            display: flex;
            height: 40px;
            // box-shadow: inset 0 0 0px 2px #fff;
            border-radius: 5px;
            justify-content: center;
            align-items: center;
            background: var(--bs-secondary-bg-subtle);
            margin: 0 1px;
          }

          &.roadmap-day-today {
            .roadmap-cell {
              background: var(--bs-primary);
              color: var(--bs-white);
            }
          }

          &.roadmap-day-yesterday {
            .roadmap-cell {
              background: var(--bs-success);
              color: var(--bs-white);
            }
          }
        }

        &.roadmap-child {
          position: relative;

          .roadmap-cell {
            height: 40px;
            box-shadow: inset 0 0 0px 0px #333;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: auto;
            font-size: 14px;
            border: 0px solid #f00;
            margin-left: 20px;
            margin-right: 10px;
            padding-right: 5px;
            background-color: var(--roadmap-color);
            color: #fff;
            cursor: pointer;

            &:hover {
              background-color: #666;

              .roadmap-cell-before {
                border-color: #666 #666 #666 #fff;
              }

              .roadmap-cell-after {
                border-color: transparent transparent transparent #666;
              }
            }

            .roadmap-cell-before {
              display: block;
              border-width: 20px 10px 20px 10px;
              border-color: var(--roadmap-color) var(--roadmap-color)
                var(--roadmap-color) #fff;
              border-style: solid;
              background-color: var(--roadmap-color);
              position: absolute;
              left: 0;
              top: 0px;
              width: 0px;
              height: 0px;
              outline: none;
              box-shadow: none;
            }

            .roadmap-cell-after {
              display: block;
              border-width: 20px 10px 20px 10px;
              border-color: transparent transparent transparent
                var(--roadmap-color);
              border-style: solid;
              position: absolute;
              right: -10px;
              top: 0px;
              width: 0px;
              height: 0px;
            }
          }
        }
      }
    }
  }
}
