.topbar {
  .container-fluid {
    padding: 0;

    .navbar-brand {
      background: url(../../assets/images/logo.svg) no-repeat 0 50%;
      background-size: 20px;
      font-weight: bold;
      padding-left: 24px;
    }
  }
}
